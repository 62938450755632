
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { isPositiveInteger } from "@/utils/verify";
import noticeChannel from "@/views/marketing/components/notice-channel.vue";
import QRCode from "qrcodejs2";
import t from "@common/src/i18n/t";
const marketing = namespace("marketing");
const base = namespace("base");
const message = namespace("message");
const app = namespace("app");
const user = namespace("user");

import { emptyToLine, splitThousands } from "@/utils/common";

@Component({
  components: {
    noticeChannel
  }
})
export default class messageRecharge extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action queryRemindSetting;
  @marketing.Action createRemindSetting;
  @message.Action getConsumerStatistic;
  @message.Action getMessageSetItem;
  @message.Action createRecharge;
  @message.Action getPaymentsuccess;
  @app.State btnLoading;
  emptyToLine = emptyToLine;
  @user.State userData;
  t = t;
  //计算属性
  get breadData() {
    return [];
  }
  ruleForm: any = {
    resource: "1"
  };
  rules = {
    combo: [{ required: true, message: t("v210831.please-select-package"), trigger: "blur" }],
    resource: [{ required: true, message: t("finance.select-mode-payment"), trigger: "change" }]
  };
  checked: number = -1;
  dialogVisible: boolean = false;
  dialogshow: boolean = false;
  intervalID: any = "";
  todayUser: any = [
    {
      name: "icon-message-blue",
      title: t("v210831.available-sms-limit"),
      title2: t("v210831.recharge-record"),
      path: "/marketing/recharge-record",
      tNum: "",
      class: "blue"
    },
    {
      name: "icon-message-green",
      title: t("v210831.sms-sent"),
      title2: t("v210831.send-record"),
      path: "/marketing/sent-record",
      tNum: "",
      class: "lime"
    }
  ];
  orderfromdata: any = {
    amount: "",
    itemCode: "",
    paymentMethod: 1,
    itemName: ""
  };
  paysuccess: boolean = false;
  payurl: any = "";
  qrCode(url) {
    document.getElementById("qrcode").innerHTML = "";
    let qrcode = new QRCode("qrcode", {
      width: 120, //图像宽度
      height: 120, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    });
    qrcode.clear(); //清除二维码
    qrcode.makeCode(url); //生成另一个新的二维码
  }
  get payText() {
    if (this.ruleForm.resource === "1") {
      return t("service.WeChat-Pay");
    } else {
      return t("service.Alipay");
    }
  }
  get name() {
    return this.userData.firstName;
  }
  comboList: any = [];
  handleLink(path) {
    this.$router.push({ path });
  }
  handleknow() {
    this.dialogshow = false;
  }
  handleClose(done) {
    window.clearInterval(this.intervalID);
    this.dialogVisible = false;
    if (this.paysuccess) {
      this.dialogshow = true;
      window.location.reload();
    } else {
      this.$message.error(t("v210831.payment-has-not-again-pay"));
    }

    // this.$confirm('确认关闭？')
    //     .then(_ => {
    //     done();
    //     })
    //     .catch(_ => {});
  }
  handlechecked(itemCode, itemPrice, itemName, quantity) {
    this.checked = itemCode;
    this.orderfromdata.amount = itemPrice;
    this.orderfromdata.itemCode = itemCode;
    this.orderfromdata.itemName = itemName;
    this.orderfromdata.quantity = quantity;
    // console.log(this.checked, "this.checked");
  }
  created() {
    this.init();
  }
  paymethod(status) {
    this.orderfromdata.paymentMethod = status;
    // console.log(this.orderfromdata.paymentMethod, "bbb");
  }
  handlepay() {
    // if (this.checked !== -1 && this.orderfromdata.paymentMethod !== "") {
    //创建充值
    console.log("[ this.orderfromdata ]", this.orderfromdata);
    this.createRecharge({
      ...this.orderfromdata
    }).then(data => {
      this.dialogVisible = true;
      this.payurl = data.data.paymentInfo;
      console.log(this.payurl, "this.payurl");

      //生成微信二维码
      setTimeout(() => {
        this.qrCode(data.data.paymentInfo);
      }, 200);
      //开启轮询
      this.intervalID = setInterval(() => {
        this.getPaymentsuccess({ rechargeOrderNo: data.data.rechargeNo }).then(data => {
          this.paysuccess = data.data;
          if (this.paysuccess) {
            window.clearInterval(this.intervalID);
            this.dialogVisible = false;
            this.dialogshow = true;
            this.getDetail();
          }
        });
      }, 1000);
    });
    // } else {
    //   this.$message.warning("请选择套餐或支付方式!");
    // }
  }
  mounted() {}
  destroy() {
    window.clearInterval(this.intervalID);
  }
  getDetail() {
    this.getConsumerStatistic().then(data => {
      this.todayUser[0].tNum = data.data.balance;
      this.todayUser[1].tNum = data.data.consumerCount;
    });
  }
  init() {
    this.getDetail();
    this.getMessageSetItem({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.comboList = this.$safeValue(data, "data.list", []);
      //默认选中第一个套餐
      this.checked = this.comboList[0].itemCode || -1;
      this.orderfromdata.amount = this.comboList[0].itemPrice;
      this.orderfromdata.itemCode = this.comboList[0].itemCode;
      this.orderfromdata.itemName = this.comboList[0].itemName;
      this.orderfromdata.quantity = this.comboList[0].quantity;
    });
  }
  showPaymentdialog() {
    this.dialogVisible = true;
  }
  submitForm(returnForm) {
    let refForm = this.$refs[returnForm] as any;
    refForm.validate(valid => {});
  }
}
